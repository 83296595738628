<template>
  <van-tabbar
    v-if="show"
    v-model="active"
    class="tab-bar pornography-class"
  >
    <!-- 首页 -->
    <van-tabbar-item to="/Home">
      <template #icon="props">
        <div class="tab active-tab">
          <img v-if="props.active" class="img-icon" src="/img/footer/home_active.png">
          <img v-if="!props.active" class="img-icon" src="/img/footer/home.png">
          <span class="text van-ellipsis">{{ $t("foorter.index") }}</span>
        </div>
      </template>
    </van-tabbar-item>

    <!-- 直播 -->
    <van-tabbar-item to="/Live">
      <template #icon="props">
        <div class="tab active-tab">
          <img v-if="props.active" class="img-icon" src="/img/footer/live_active.png">
          <img v-if="!props.active" class="img-icon" src="/img/footer/live.png">
          <span class="text van-ellipsis">สตรีมมิ่งสด</span>
        </div>
      </template>
    </van-tabbar-item>


    <!-- 中间 -->
<!--    <van-tabbar-item to="/Choose">-->
<!--      <template #icon="props">-->
<!--        <div class="tab active-tab">-->
<!--          <img v-if="props.active" class="img-icon" src="/img/footer/beauty.png">-->
<!--          <img v-if="!props.active" class="img-icon" src="/img/footer/beauty.png">-->
<!--        </div>-->
<!--      </template>-->
<!--    </van-tabbar-item>-->


    <!-- 福利 -->
    <van-tabbar-item to="/Welfare">
      <template #icon="props">
        <div class="tab active-tab">
          <img v-if="props.active" class="img-icon" src="/img/footer/welfare_active.png">
          <img v-if="!props.active" class="img-icon" src="/img/footer/welfare.png">
          <span class="text van-ellipsis">{{ $t("foorter.welfare") }}</span>
        </div>
      </template>
    </van-tabbar-item>

    <!-- 预约 -->
    <van-tabbar-item to="/Mine">
      <template #icon="props">
        <div class="tab active-tab">
          <img v-if="props.active" class="img-icon" src="/img/footer/mine_active.png">
          <img v-if="!props.active" class="img-icon" src="/img/footer/mine.png">
          <span class="text van-ellipsis">{{ $t("foorter.my") }}</span>
        </div>
      </template>
    </van-tabbar-item>

  </van-tabbar>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      active: 0
    };
  },
  methods: {},
  watch: {
    $route(to) {
      if (to.name == "home") {
        this.active = 0;
        this.show = true;
      } else if (to.name == "live") {
        this.active = 1;
        this.show = true;
      // } else if (to.name == "choose") {
      //   this.active = 2;
      //   this.show = true;
      } else if (to.name == "welfare") {
        this.active = 2;
        this.show = true;
      }  else if (to.name == "mine") {
        this.active = 3;
        this.show = true;
      } else {
        this.show = false;
      }
    },
  },
  created() {
    if (this.$route.name == "home") {
      this.active = 0;
      this.show = true;
    } else if (this.$route.name == "live") {
      this.active = 1;
      this.show = true;
    // } else if (this.$route.name == "choose") {
    //   this.active = 2;
    //   this.show = true;
    } else if (this.$route.name == "welfare") {
      this.active = 2;
      this.show = true;
    } else if (this.$route.name == "mine") {
      this.active = 3;
      this.show = true;
    } else {
      this.show = false;
    }
  },
};
</script>

<style lang="less" scoped>
.tab-bar{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  height: 1.33333rem;
  box-shadow: 0 0 .13333rem 0 rgba(125,124,124,.3);
  z-index: 1;
  background-color: #1E1799
}

.tab-bar .tab{
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 .05333rem;
  overflow: hidden
}

.tab-bar .tab .icon{
  color: #979799;
  font-size: .53333rem;
  margin-bottom: .05333rem
}

.tab-bar .tab .img-icon{
  width: .8rem;
  height: .8rem;
  -o-object-fit: contain;
  object-fit: contain
}

.tab-bar .tab .text{
  width: 100%;
  color: #fff;
  font-size: .26667rem;
  font-weight: 500;
  text-align: center
}

.tab-bar .tab .main-img{
  position: absolute;
  width: 1.73333rem;
  height: 1.73333rem;
  top: -.26667rem;
  z-index: 2;
  background-color: #282828;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center
}

.tab-bar .tab .main-img img{
  width: 1.41333rem;
  height: 1.41333rem;
  -o-object-fit: contain;
  object-fit: contain
}

.tab-bar .active-tab .icon{
  color: #e6b98a
}

.tab-bar .active-tab .text{
  color: #f3c7a2
}
.van-tabbar-item--active{
  background-color: #1E1799;
}
.tab-bar .tab .text {
  width: 100%;
  color: #fff;
  font-size: .26667rem;
  font-weight: 500;
  text-align: center;
}
</style>
