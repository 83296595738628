<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar :title="$t('transactions.title')" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()"/>
        </template>
      </van-nav-bar>
    </div>
    <div class="main">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh"  pulling-text=" " loosing-text=" " loading-text="...">
        <van-empty v-if="list.length === 0" :description="$t('withdraw.empty_data')" />
        <div v-else class="item_list" v-for="(v,key) in list" :key="key">
          <div class="topInfo">
            <span class="username">{{username}}</span>
            <span style="color: #e6b98a">{{v.money}}</span>
          </div>
          <div class="statusRow">
            <span>{{v.update_time}}</span>
            <span>{{v.status_text}}</span>
          </div>
          <div v-if="v.type==1 && v.desc!=='' && v.status==3" class="statusRow" style="display: flex;">
            <span style="text-align: right;">{{v.desc}}</span>
          </div>
          <div v-if="v.type==2 && v.desc!=='' && v.status==3" class="statusRow" style="display: flex;">
            <span style="text-align: right;">{{v.desc}}</span>
          </div>
        </div>
        <div style="margin: 10px auto;background: #fff;" >
          <van-pagination v-model="page" :total-items="total" :items-per-page="page_num" :prev-text="$t('agent.pre_page')" :next-text="$t('agent.next_page')" mode="simple" @change="changePage" />
        </div>
      </van-pull-refresh>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      page:1,
      page_num:12,
      total:0,
      count:0,
      list:[],
      userInfo:{},
      username:'',
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    onRefresh() {
      setTimeout(() => {
        this.isLoading = true;
        this.getUserWithdrawList();
        this.$toast(this.$t("reservation.refresh"));
        this.isLoading = false;
      }, 500);
    },
    getUserWithdrawList(){
      this.$http({
        method: 'get',
        data:{page:this.page},
        url: 'user_get_transactions_list'
      }).then(res=>{
        if(res.code === 200){
          this.list = res.data.data;
          this.total = res.data.total;
          this.page = res.data.page;
          this.page_num = res.data.page_num;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
          this.username = res.data.username;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    changePage(page){
      this.page = page;
      this.getUserWithdrawList();
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserWithdrawList();
      this.getUserInfo();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #000000;
  font-size: 35px;
}

::v-deep .van-loading__text {
  color: #000000;
  font-size: 35px;
}
.container .main{
  position: relative;
  overflow: auto;
  background-color: #1E1799;
  height: 100%;
  padding: 0 10px;
}

.item_list{
  padding: 15px 15px;
  margin: 30px 10px;
  background: #fff;
  border-radius: 10px;
  line-height: 60px;
}

.item_list .topInfo span{
  flex: 1;
  font-size: 35px;
  font-weight: 700;
  color: #ff253f;
}
.item_list .topInfo span.username{
  font-weight: initial;
  color: #9b9b9b;
}
.item_list .time span{
  flex: 1;
  font-size: 25px;
  font-weight: 500;
  color: #000;
}
.item_list .topInfo span:last-child{
  float: right;
}
.item_list .desc span{
  font-size: 25px;
  font-weight: 700;
  color: #9b9b9b;
}
.item_list .statusRow span{
  flex: 1;
}
.item_list .statusRow span:last-child{
  float: right;
}
</style>
