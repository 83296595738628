<template>
  <div class="container page">
    <van-nav-bar class="header">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
    </van-nav-bar>

    <div class="banner">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item>
          <img class="banner_img" round :src="this.$route.query.imgUrl">
        </van-swipe-item>
        <van-swipe-item>
          <img class="banner_img" round :src="this.$route.query.detail1">
        </van-swipe-item>
        <van-swipe-item>
          <img class="banner_img" round :src="this.$route.query.detail2">
        </van-swipe-item>
      </van-swipe>
    </div>

    <div class="notice-bar">
      <div class="wrapper">
        <img class="newmovie-notice-icon" src="/img/home/horn.png">
        <div class="notice">
          <van-notice-bar
              class="notice-swipe"
              background="transparent;"
              color="color: rgb(0, 0, 0);"
              :text="this.notice"
          />
        </div>
        <div class="gonggao-div newmovie-notice-icon"><span>ประกาศ</span></div>
      </div>
    </div>

    <div class="detail-body">
      <div><span>Id: {{this.$route.query.code}}</span><span>เรียกใช้การแสดงผล</span></div>
      <div>
        <div><span>คะแนนระดับรูปลักษณ์</span><span>99</span><span>ลักษณะความงาม</span></div>
        <div><span>ที่อยู่：ติดต่อ ที่ปรึกษา ที่ซ่อนอยู่เพื่อเปิดใช้งานการมองเห็น</span><span>ข้อมูลการติดต่อ: ซ่อนข้อมูลติดต่อ ที่ปรึกษา เพื่อเปิดใช้งานการมองเห็น</span></div>
      </div>
      <div>
        <ul>
          <li><img src="/img/home/info.png"><span>บุคลิก: {{this.$route.query.character}},ความสูง: {{this.$route.query.high}}</span></li>
          <li><img src="/img/home/location.png"><span>พื้นที่ {{this.$route.query.area}}</span></li>
          <li><img src="/img/home/servers.png"><span>การเปิดใช้งานโครงการบริการ</span></li>
          <li><span>ขอให้สมาชิกทุกท่าน สนุกเเละมีการเดทสุดเเสนโรเเมนติก  เเละ คืนนี้ขอให้เป็นค่ำคืนที่ดีของคุณ</span></li>
        </ul>
      </div>
      <van-image class="body-img" :src="this.$route.query.detail1">
        <template v-slot:loading>
          <van-loading type="circular"/>
        </template>
      </van-image>
      <van-image class="body-img" :src="this.$route.query.detail2">
        <template v-slot:loading>
          <van-loading type="circular"/>
        </template>
      </van-image>
      <div class="btn-div" @click="message()">กรุณาติดต่ออาจารย์พิเศษ</div>
    </div>

  </div>
</template>

<script>
// import {swiper, swiperSlide} from "vue-awesome-swiper";

export default {
  // components: {swiper,
  //   swiperSlide},
  data() {
    return {
      notice:"ยินดีต้อนรับสู่ LOVOO ศูนย์รวมสาวๆทั่วประเทศ",
      bannerSwiperOption: {
        direction: 'horizontal', // 垂直滚动vertical
        autoplay: {
          delay: 2500, // 自动播放的延迟时间为 2500 毫秒
          disableOnInteraction: false, // 用户交互后仍继续自动播放
          isableOnInteraction: false, // 确保交互后还能继续自动播放
        },
        loop: true, // 循环播放
        speed: 500, // 滑动速度
      }
    }
  },
  methods:{
    back(){
      return window.history.back();
    },
    message(){
      this.$toast("กรุณาติดต่ออาจารย์พิเศษ");
    }
  },
  mounted () {

  },
  created() {

  }
}

</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.container .header{
  position: fixed;
  top: 0;
  z-index: 1000;
}
.banner .full-class {
  padding: 0!important
}

.banner_img {
  width: 100%;
  height: 130vw;
  object-fit: cover;
}

.banner .swiper-container {
  width: 100%;
}

.banner .swiper-container .swiper-wrapper .swiper-slide .padding-b {
  position: relative;
  padding-bottom: 130%
}

.banner .swiper-container .swiper-wrapper .swiper-slide .padding-b .img-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0
}

.banner .swiper-container .swiper-wrapper .swiper-slide .padding-b .img-wrapper .img {
  width: 100%;
  height: 100%
}

.banner .swiper-container .swiper-wrapper .swiper-slide.swiper-slide-active {
  transform: scale(1)
}

.notice-bar {
  padding: 0 0.13333rem;
  background-color: transparent;
  border: 0.008rem solid #e6b98a;
  border-radius: 0.10667rem;
  height: 1.06667rem;
  margin: 0.13333rem 0.26667rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.notice-bar .wrapper {
  display: flex;
  flex: 1;
  align-items: center;
}
.notice-bar .wrapper img {
  height: 0.66667rem;
}
.notice-bar .wrapper .notice {
  margin-left: 0.26667rem;
  flex: 1;
  height: 0.8rem;
  overflow: hidden;
}
.notice-bar .wrapper .notice .van-notice-bar {
  height: 100% !important;
}
.notice-bar .wrapper .gonggao-div {
  height: 0.53333rem;
  width: 2rem;
  background-image: url('/img/home/toole.png');
  line-height: .53333rem;
  text-align: center;
}
.notice-bar .wrapper .notice .notice-content * {
  display: inline-block;
  white-space: nowrap;
  font-size: .37333rem !important;
  color: #fff !important;
}
.van-notice-bar__wrap {
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  height: 100%;
  overflow: hidden;
}


.detail-body {
  width: calc(100% - .53333rem);
  margin: 0 auto
}

.detail-body span {
  color: #fff
}

.detail-body>div:first-child {
  height: 1.06667rem;
  margin: .26667rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between
}

.detail-body>div:first-child span {
  font-size: .37333rem
}

.detail-body>div:nth-child(2) {
  border: .02667rem solid #7e6a56;
  height: 2.13333rem;
  margin-bottom: .26667rem;
  border-radius: .13333rem;
  display: flex;
  align-items: center;
  justify-content: center
}

.detail-body>div:nth-child(2)>div {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center
}

.detail-body>div:nth-child(2)>div span {
  font-size: .34667rem
}

.detail-body>div:nth-child(2)>div:first-child {
  width: 2.66667rem
}

.detail-body>div:nth-child(2)>div:first-child>span:nth-child(2) {
  color: #f3d3b8;
  font-size: .53333rem;
  font-weight: 700
}

.detail-body>div:nth-child(2)>div:nth-child(2) {
  width: 6.66667rem
}

.detail-body>div:nth-child(3) {
  border: .02667rem solid #7e6a56;
  height: 4.53333rem;
  border-radius: .13333rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 .26667rem;
  margin-bottom: .26667rem
}

.detail-body>div:nth-child(3) ul li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: .8rem
}

.detail-body>div:nth-child(3) ul li img {
  height: .48rem;
  margin-right: .13333rem
}

.detail-body>div:nth-child(3) ul li span {
  font-size: .34667rem
}

.detail-body>div:nth-child(3) ul>li:nth-child(3) {
  height: 1.06667rem;
  border-bottom: .05333rem solid #7e6a56
}

.detail-body>div:nth-child(3) ul>li:nth-child(4) {
  margin-top: .13333rem
}


.detail-body .body-img {
  width: 100%
}

.detail-body .btn-div {
  width: 70%;
  height: .96rem;
  background: linear-gradient(#fde3ca,#e7b486);
  text-align: center;
  margin: .32rem auto;
  line-height: .96rem;
  color: #333;
  border-radius: .48rem;
  font-size: .37333rem
}
.detail-body .body-img {
  width: 100%;
}
.detail-body .btn-div {
  width: 70%;
  height: 0.96rem;
  background: linear-gradient(#fde3ca, #e7b486);
  text-align: center;
  margin: 0.32rem auto;
  line-height: .96rem;
  color: #333;
  border-radius: 0.48rem;
  font-size: .37333rem;
}
</style>
