<template>
  <div class="live-container page">
    <div class="home-top-bar"><span>สตูดิโอ</span></div>


    <div class="live-scroll">
      <div class="live-item" v-for="(item,index) in liveData" :key="index">
        <div>
          <div class="avatar-div">
            <img class="avatar-img" :src="item.imgUrl">
            <img class="live-img" src="/img/live/live.gif">
          </div>
          <ul>
            <li>{{item.name}}</li>
            <li>นางฟ้า ที่ได้รับการรับรองอย่างเป็นทางการ</li>
<!--            <li>ได้มัดจำ  50,000 บาท</li>-->
            <li>พื้นที่:{{item.area}}</li>
          </ul>
        </div>
        <div>
          <div class="video-img-div" @click="toLiveVideo(item)">
            <img :src="item.imgUrl">
            <span class="play"><b></b></span>
          </div>
          <ul>
            <li>ลายเซ็นต์: Big Data แนะนำเธอให้คุณ</li>
            <li>
              <div v-for="(tag, k) in item.tag" :key="k">{{ tag }}</div>
            </li>
            <li @click="message()"><img src="/img/live/girl.png"><span>รับข้อมูลการติดต่อ</span></li>
          </ul>
        </div>
      </div>


    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      basicData:[],
      dialog:false,

      liveData:[
        {id:'1', name: 'โน๋เนส', tag:['น่ารัก','คุยเก่ง','ขี้อ้อน'], area: 'ลพบุรี', high: '165', imgUrl: '/img/live/1.jpg', video: '1'},
        {id:'2', name: 'แพม',tag:['ขี้อ้อน','เอาใจเก่ง','ไม่งอแง','บริการดี'], area: 'เชียงใหม่', high: '165', imgUrl: '/img/live/2.jpg', video: '2'},
        {id:'3', name: 'อ๋อมแอ๋ม', tag: ['ยิ้มง่าย','เอาใจเก่ง','หุ่นแซ่บ'], area: 'อุดร', high: '160', imgUrl: '/img/live/3.jpg', video: '3'},
        {id:'4', name: 'มินตรา', tag: ['นิสัยดี','เอาใจเก่ง','ไม่งอแง'], area: 'กำแพงเพชร', high: '168', imgUrl: '/img/live/4.jpg', video: '4'},
        {id:'5', name: 'วิว', tag: ['ผิวขาวเนียน','นิสัยดี','ขี้เล่น'], area: 'กบินบุรี', high: '158', imgUrl: '/img/live/5.jpg', video: '5'},
        {id:'6', name: 'อีฟ', tag: ['อวบ','น่าขย้ำ','ยิ้มเก่ง'], area: 'กรุงเทพ', high: '161', imgUrl: '/img/live/6.jpg', video: '6'},
        {id:'7', name: 'บีเบลล์', tag: ['ผิวขาว','เอาใจเก่ง','ขี้อ้อน'], area: 'นนทบุรี', high: '158', imgUrl: '/img/live/7.jpg', video: '7'},
        {id:'8', name: 'พิมมี่', tag: ['ตัวเล็ก','หน้าคม','เอาใจเก่ง'], area: 'ปทุมธานี', high: '159', imgUrl: '/img/live/8.jpg', video: '8'},
        {id:'9', name: 'อ้อม', tag: ['อวบอึม','เต็มมือ','ลีลาเด็ด','สวย'], area: 'พังงา', high: '160', imgUrl: '/img/live/9.jpg', video: '9'},
        {id:'10', name: 'ปิ่น', tag: ['เป็นกันเอง','น่ารัก','คุยเก่ง'],area: 'ลพบุรี', high: '162', imgUrl: '/img/live/10.jpg', video: '10'},
        {id:'11', name: 'โบว์ ลิ่งค์', tag: ['น่ารัก','คุยเก่ง','ขี้อ้อน','ไม่งอแง'], area: 'กระบี่', high: '155', imgUrl: '/img/live/11.jpg', video: '11'},
        {id:'12', name: 'หญิง', tag: ['น่ารัก','ขี้อ้อน','เอาใจเก่ง'], area: 'ลำปาง', high: '160', imgUrl: '/img/live/12.jpg', video: '12'},
        {id:'13', name: 'มุนิน', tag: ['ยิ้มเก่ง','ขี้อ้อน','เอาใจเก่ง'], area: 'เชียงราย', high: '152', imgUrl: '/img/live/13.jpg', video: '13'},
        {id:'14', name: 'ใบเฟิร์น', tag: ['เป็นกันเอง','อัธยาศัยดี','คุยเก่ง'], area: 'พัทยา', high: '166', imgUrl: '/img/live/14.jpg', video: '14'},
        {id:'15', name: 'เฟิร์น', tag: ['ตัวเล็ก','นิสัยดี','ยิ้มเก่ง','เอวดี'], area: 'อุดรธานี', high: '158', imgUrl: '/img/live/15.jpg', video: '15'},
      ]
    };
  },
  methods:{
    getBasicConfig(){
      this.$http({
        method: 'get',
        url: 'sys_config'
      }).then(res=>{
        this.basicData = res.data;
      })
    },
    message(){
      this.$toast("กรุณาติดต่ออาจารย์พิเศษ");
    },

    toLiveVideo(item){
      this.$router.push({path:'/LiveVideo',query:{...item}})
    },
  },
  mounted () {

  },
  created() {
    this.getBasicConfig();
  }
}
</script>

<style lang='less' scoped>
.live-container{
  height: calc(100% - 1.33333rem)
}

.live-container .live-scroll{
  width: calc(100% - .53333rem);
  height: calc(100% - 1.06667rem);
  margin: 0 auto;
  overflow: auto;
  scrollbar-width: none;
}

.live-container .banner{
  height: 4.1984rem
}

.home-top-bar{
  width: 100%;
  height: 1.06667rem;
  padding: 0 0.4rem 0 0.26667rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-top-bar span:first-child {
  font-size: .48rem;
}
.home-top-bar span{
  color: #f3d3b8;
}


.live-item{
  width: 100%;
  background: linear-gradient(#1E1799,#1E4CFA);
  border-radius: .26667rem;
  margin-bottom: .26667rem
}

.live-item>div{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between
}

.live-item>div>{
  height: 100%
}

.live-item>div>div{
  display: flex;
  align-items: center;
  justify-content: center
}

.live-item>div>ul{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: .13333rem
}

.live-item>div>ul>li{
  display: flex;
  align-items: center;
  justify-content: flex-start
}

.live-item>div:first-child {
  height: 2.4rem;
  margin-bottom: .13333rem
}

.live-item>div:first-child .avatar-div{
  width: 2rem;
  margin: 0 .53333rem;
  position: relative
}

.live-item>div:first-child .avatar-div .avatar-img{
  width: 2rem;
  height: 2rem;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%
}

.live-item>div:first-child .avatar-div .live-img{
  width: .53333rem;
  position: absolute;
  right: -.26667rem;
  top: calc(50% + .13333rem)
}

.live-item>div:first-child>ul{
  width: 6.4rem
}

.live-item>div:first-child>ul>li{
  font-size: .37333rem;
  font-weight: 700;
  color: #fff
}

.live-item>div:first-child>ul>li:first-child {
  position: relative
}

.live-item>div:first-child>ul>li:first-child:before {
  content: "";
  display: inline-block;
  width: .53333rem;
  height: .45333rem;
  border-radius: .08rem;
  background: #e5338b;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAABmklEQVQ4jZWUPShGYRTH/6+PwUdJkqR8lAFlJEmZLWIzGJmURZmYGJiUYrJTysckg8SkDEgkA1GEQryDFH56dN56vPd5r9epM9zz8bvnnHvOTQDKUkokVUpKSrqX9BFMc8AYLQBGgAN+ywuwCLSm58bBGoBzw7wCa8AMsAAcmv0TmAASfwHrgQdLmASKAzFtwLGBZ+OAecAR8AX0Bfy+uhftGbQnE7Av/a1/aDXwBpy61kPBK9ZqVcCXSResiJacyGeX2iWdSLqJeDLLpnmCwApJtxFrvNyZtyQEfJZUHrHGS5l5n0LAfUnNkkojnszSaZ6z0JAHbcBjAV9IS4En4MatXCqgA5gDiuzcLoB3oD0A8DUXWLcCBv09HDbjrkGn7DkJ9Pun5anbvw2LW03FpAISViG2oO5KroBHzzYNDAGjwLIts5Ml6ypyeg665R19E1AJzNvPIV3cefamV+8DB6yypCXuWPvOl++uAOgGuoCawAh+AVOwS6DOa3/bBp/tCf4AGz1Yrde++zlcA4X/BbqBjttXyzoxqKBvZFvitzhRgooAAAAASUVORK5CYII=);
  background-size: 60%;
  background-position: 50%;
  background-repeat: no-repeat;
  position: absolute;
  left: 50%;
  top: .02rem
}

.live-item>div:first-child>ul>li:nth-child(2) {
  color: #7ef0f4
}

.live-item>div:first-child>ul>li:nth-child(3) {
  color: #ffffff;
}

.live-item>div:nth-child(2) {
  height: 4.8rem
}

.live-item>div:nth-child(2) .video-img-div{
  position: relative
}

.live-item>div:nth-child(2) .video-img-div img{
  width: 3.38667rem;
  height: 4.4rem;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: .26667rem
}

.live-item>div:nth-child(2) .video-img-div .play{
  position: absolute;
  top: calc(50% - .48rem);
  left: calc(50% - .48rem);
  width: .96rem;
  height: .96rem;
  line-height: .4rem;
  border-radius: 50%;
  background-color: rgba(0,0,0,.3215686274509804)
}

.live-item>div:nth-child(2) .video-img-div .play b{
  content: "";
  display: inline-block;
  position: absolute;
  top: .48rem;
  left: .66667rem;
  transform: translate(-50%,-50%);
  height: 0;
  width: 0;
  border-style: solid;
  border-width: .26667rem;
  border-color: transparent transparent transparent #fff;
  vertical-align: middle;
  margin-top: .02rem
}

.live-item>div:nth-child(2)>ul{
  width: 5.73333rem
}

.live-item>div:nth-child(2)>ul>li{
  color: #fff
}

.live-item>div:nth-child(2)>ul>li:first-child {
  font-size: .34667rem;
  color: #ffffff;
  margin-bottom: .13333rem
}

.live-item>div:nth-child(2)>ul>li:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap
}

.live-item>div:nth-child(2)>ul>li:nth-child(2)>div{
  height: .48rem;
  padding: 0 .4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: .24rem;
  margin: .13333rem .53333rem .13333rem 0
}

.live-item>div:nth-child(2)>ul>li:nth-child(2)>div:first-child {
  background-color: #e9335d
}

.live-item>div:nth-child(2)>ul>li:nth-child(2)>div:nth-child(2) {
  background-color: #d14a42
}

.live-item>div:nth-child(2)>ul>li:nth-child(2)>div:nth-child(3) {
  background-color: #285ab7
}

.live-item>div:nth-child(2)>ul>li:nth-child(2)>div:nth-child(4) {
  background-color: #eb46b9
}

.live-item>div:nth-child(2)>ul>li:nth-child(3) {
  width: 4.26667rem;
  height: .69333rem;
  margin-top: .26667rem;
  text-align: center;
  border-radius: .34667rem;
  background-image: linear-gradient(90deg,#e34c6f,#ef9163);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center
}

.live-item>div:nth-child(2)>ul>li:nth-child(3) img{
  height: .42667rem;
  margin-right: .13333rem
}

.live-item>div:nth-child(2)>ul>li:nth-child(3) span{
  font-size: .37333rem
}

.server-dialog {
  position: fixed;
  display: flex;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 7.46667rem;
  height: 4.26667rem;
  z-index: 16;
  background-color: #262626;
  border: .02667rem solid #7e6a56;
  border-radius: .08rem
}

.server-dialog ul {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center
}

.server-dialog ul>li {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: .24rem
}

.server-dialog ul>li:first-child {
  color: #e6b98a;
  font-size: .48rem;
  font-weight: 700;
  margin: .10667rem
}

.server-dialog ul>li:nth-child(2) {
  font-size: .4rem;
  color: #fff
}

.server-dialog ul>li:nth-child(3) {
  background: linear-gradient(180deg,#efcba7,#dcab76);
  color: #8a5623;
  font-size: .37333rem;
  width: 4.26667rem;
  height: .58667rem;
  border-radius: .29333rem
}

.scale-enter-from {
  opacity: 0;
  transform: scale(.7)
}

.scale-leave-to {
  opacity: 0
}

.scale-enter-to,.scale-leave-from {
  transition: all .15s cubic-bezier(.97,1.73,.85,.77)
}
</style>
